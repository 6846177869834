<template>
  <div class="landing-page">
    <div class="login ml-auto mr-auto">
      <div class="card" title="Login">
        <div class="card-header">
          <img src="@/assets/Logo_EPOS.png" class="logo" alt="EPOS logo" />
        </div>
        <div class="card-body">
          <template v-if="!emailCode">
            <p>{{ $t('no-code-line-one') }}</p>
            <p>{{ $t('no-code-line-two') }}</p>
          </template>
          <template v-if="emailCode && !user">
            <p>{{ $t('loading') }}</p>
          </template>
          <template v-if="emailCode && user && user.type === 'pretoken'">
            <p>{{ $t('form-line-one', { phone: user.mobile }) }}</p>

            <form @submit.prevent="loginBySmsCode">
              <div class="form-group">
                <input
                  ref="smsLoginCodeInput"
                  required
                  type="text"
                  autocomplete="one-time-code"
                  pattern="[0-9]*"
                  v-model="smsCode"
                  class="form-control"
                  inputmode="numeric"
                  id="sms-code"
                  aria-describedby="verification_code"
                  :placeholder="$t('received-code-placeholder')"
                />
              </div>

              <div
                :class="
                  isSmall ? 'sms-code-buttons-mobile' : 'sms-code-buttons'
                "
                class="mb-3"
              >
                <button
                  type="submit"
                  class="btn btn-novacair-light"
                  :class="{ 'btn-mobile': isSmall }"
                >
                  {{ $t('continue-button') }}
                </button>
              </div>
            </form>

            <p>
              {{ $t('no-code-received')
              }}<a @click="resend" class="resend-link">
                {{ $t('resend-code-link') }}</a
              >
            </p>

            <div class="alert alert-danger" v-if="warning">
              {{ warning }}
            </div>
            <div class="alert alert-info" v-if="confirm">
              {{ confirm }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// This component name is a preexisting condition, disable inspection.
/* eslint vue/multi-word-component-names: 0 */
import { accessAuth, getScreeningByUUID, resendSms, smsAuth } from '@/api';
import { getCurrentUser, parseToken } from '@/lib/functions';
import { availableLocales } from '@/i18n';

export default {
  name: 'Login',
  data() {
    return {
      user: getCurrentUser(),
      emailCode: this.$route.query.screening,
      accessCode: this.$route.query.access,
      smsCode: null,
      passCode: null,
      email: null,
      password: null,
      warning: null,
      confirm: null,

      // Language set explicitly through the query, only if valid
      explicitLanguage: null,
      headful: {
        title: 'E+POS - Login',
        description: this.$t('title'),
      },
    };
  },
  computed: {
    isSmall() {
      return this.$mq === 'small';
    },
  },
  mounted() {
    const routeLanguage = this.$route.query.language?.toString();

    if (routeLanguage && availableLocales.includes(routeLanguage)) {
      // If a language was explicitly set through the query, and it's
      // a language we recognize (i.e. one we send in the email),
      // request all SMS messages in that language from now on.
      this.explicitLanguage = routeLanguage;
    }

    if (this.accessCode) {
      this.loginByAccessCode();
      return;
    }

    if (this.emailCode) {
      this.loginByEmailCode();
    }

    this.$nextTick(() => {
      this.$refs.smsLoginCodeInput?.focus();
    });
  },
  methods: {
    logout() {
      sessionStorage.removeItem('token');
      this.user = null;
    },
    processLogin(response) {
      this.warning = null;

      const token = response.data;
      sessionStorage.setItem('token', token);
      this.user = parseToken(token);

      // The token has type "token" or "pretoken" depending
      // on whether 2FA is still necessary.
      if (this.user?.type === 'pretoken') {
        // Get rid of the screening UUID in the query string. This way, if somebody leaves
        // a tab open, it won't continuously spam new text messages when reloading.
        // We stay on the same page though, so the screening UUID is retained in memory.
        this.$router.replace('/login');
      } else {
        // Load the screening
        this.$router.replace('/');
      }
    },
    processError() {
      this.warning = this.$t('failed');
    },
    loginByEmailCode() {
      getScreeningByUUID(this.emailCode, this.explicitLanguage)
        .then(this.processLogin)
        .catch(() => (this.emailCode = null));
    },
    loginBySmsCode() {
      this.warning = null;

      smsAuth(this.emailCode, this.smsCode)
        .then(this.processLogin)
        .catch(this.processError);
    },
    async loginByAccessCode() {
      this.warning = null;
      try {
        const response = await accessAuth(this.accessCode);
        this.processLogin(response);
      } catch {
        this.processError();
      }
    },
    resend() {
      this.warning = null;

      resendSms(this.emailCode, this.explicitLanguage)
        .then(() => (this.confirm = this.$t('new-code-sent')))
        .catch(this.processError);
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding-top: 60px;
  max-width: 550px;
}

.logo {
  height: 30px;
  margin: 10px 0 10px 0px;
}

.btn-mobile {
  width: 250px;
}

.resend-mobile {
  margin-left: 0px !important;
  margin-top: 15px;
}

.sms-code-buttons-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.btn-novacair-light {
  border: 1px solid $primary;
  background-color: white;
  color: black;
  padding: 5px 30px;
}

.alert {
  margin-top: 20px;
}

.resend {
  margin-left: 10px;
}

.resend-link {
  cursor: pointer;
  font-weight: bold;
  color: #154866 !important;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.alert-info {
  color: #154866 !important;
}
</style>
